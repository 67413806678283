<template>
    <div>
        
      <v-layout wrap justify-center  pb-12>
  <v-flex xs12 sm10 md10 lg10 text-start pb-12 data-aos="zoom-in-up" data-aos-ease="ease"
  data-aos-duration="1500" data-aos-delay="500">
    <v-layout wrap justify-space-between>
     <v-flex xs12 sm12 md7 lg7 ><v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 pb-12>
            <v-card style="color: #1B6DC1;" class="pa-6" v-bind:class="{ expanded: isExpanded4 }">
                <v-card-title>
                    <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
                        'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
                        'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
                    }">
                    Course Objectives
                    </span>
                </v-card-title>
        
                <v-card-text style="text-align: justify;">
            <p style="font-family: JostRegular; color: #1A2421">
                <ul style="list-style-type: number; padding-left: 10;">
                <!-- Use a computed property to filter listItems based on isExpanded -->
                <li v-for="(item, index) in filteredListItems" :key="index" style="font-family: JostRegular; color: #1A2421; padding-left: 15px;">{{ item }}</li>
                </ul>
            </p>
        
            <!-- See More button -->
            <v-flex xs12>
                <!-- Use <router-link> for navigation -->
                <router-link to="" style="text-decoration: none;">
                <!-- You can use any icon library for the arrow icon, here I'm using Material Design Icons -->
                <v-btn text color="#0064CB" style="margin-left:-15px;" small @click="toggleExpansion4">
                    <v-icon color="#0064CB" style="border: 1px solid currentColor; border-radius: 50%; padding: 0px; font-size: 12px;" small>
                    mdi-arrow-right
                    </v-icon>
                    &nbsp;<span style="font-family: JostBold;">{{ isExpanded4 ? 'See Less' : 'See More' }}</span>
                    <!-- Icon for the arrow, adjust the icon and style as needed -->
                </v-btn>
                </router-link>
            </v-flex>
            </v-card-text>
                
            </v-card>
            </v-flex>

            
  </v-layout>
  </v-flex>
  <v-flex xs12 sm12 md5 lg5 pl-0 pl-xs-0 pl-sm-0 pl-md-5 pl-lg-10 pl-xl-10>
    <v-layout wrap justify-end>
        <v-flex xs12 sm12 md12 lg12>
  
  <v-card style="color: #1B6DC1;" class="pa-5">
    <v-card-title >
      <span  style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1;">
        Related Courses
      </span>
    </v-card-title>
    <v-flex pt-3 v-for="(item, i) in items" :key="i">
      <router-link style="text-decoration:none;"  :to="item.route">
      <v-card color="#E6EFF9" style="color: #1B6DC1;" class="pa-2 ">
  
        <v-layout wrap justify-center>
          <!-- Image on the left -->
          <v-flex xs3 >
            <v-img :src="item.img" width="300px" height="100px"></v-img>
          </v-flex>
  
          <v-flex xs9 pt-5>
            <v-card-text>
              <span style="font-family: OutfitBold; font-size: 18px; color: black">{{ item.title }}</span>
              <!-- Wrap the rating and span in a flex container -->
              <!-- <div style="display: flex; align-items: center;">
              
                <v-rating style="margin-left: -10px;" v-model="rating" background-color="orange lighten-3" color="orange" small half-increments></v-rating>
           
  
              </div> -->
  
            </v-card-text>
            <!-- <v-layout wrap justify-start>
              
              <v-flex xs4  pl-1>
  
                <v-icon small color="blue">mdi-clock-outline</v-icon>
                &nbsp; <span style="color: black; text-transform: capitalize; font-family:OutfitRegular;font-size:13px">{{ item.months }}</span>
  
              </v-flex>
  
          
              <v-flex xs8 pl-1>
  
                <v-icon small color="blue">mdi-book-open-outline</v-icon>
                &nbsp;<span small style="color: black; text-transform: capitalize;font-family:OutfitRegular; font-size:13px">{{ item.chapter }}</span>
  
              </v-flex>
            </v-layout> -->
          </v-flex>
        </v-layout>
  
      </v-card>
    </router-link>
    </v-flex>
  
  </v-card>
  
  </v-flex>
  </v-layout>
  </v-flex>
    </v-layout> 
  
  
  </v-flex>
  
  
  
  
        
      </v-layout>
    </div>
  </template>
  
  <script>
  export default {
  data() {
    return {
        isExpanded4: false,
       
      listItems: [
        "Understanding Manual Tasks: Get acquainted with the diverse range of manual tasks, with a spotlight on those classified as hazardous.",
        "Safety First: Acquire the knowledge and techniques to execute manual tasks safely, reducing potential harm.",
        "'No Lift' Policy Defined: Dive deep into understanding the essence of the 'No Lift' policy and its significance.",
        "Risk Management: Recognise the importance of adopting a risk management approach in manual handling, ensuring optimal safety.",
        "Basic Principles: Familiarise yourself with the foundational principles of manual handling, equipping you to face real-world scenarios with confidence.",
        
      ],
      
      // rating: 4,
      items: [
        {
            img: require("./../../assets/images/ger.png"),
            title: "German course - LEVELS A1-B2",
            route:"/coursesingleview"
            //   review: "4.5",
  
            //   months: "3 Months",
            //   chapter: "32 Chapters",
          },
          {
            img: require("./../../assets/images/office.png"),
            title: "Office Automation",
            route:"/officeautopage"
            //   review: "4.5",
            //   fullreview: "(73 Reviews)",
  
            //   months: "3 Months",
            //   chapter: "32 Chapters",
          },
          {
            img: require("./../../assets/images/tally.png"),
            title: "Tally Prime",
            route:"/tallypage"
            //   review: "4.5",
            //   fullreview: "(73 Reviews)",
  
            //   months: "3 Months",
            //   chapter: "32 Chapters",
          },
          {
            img: require("./../../assets/images/multimedia.png"),
            title: "Multimedia Course",
            route:"/multymediapage"
            //   review: "4.5",
            //   fullreview: "(73 Reviews)",
  
            //   months: "3 Months",
            //   chapter: "32 Chapters",
          },
      ],
    };
  },
  computed: {
    // Compute the filtered list based on isExpanded
    filteredListItems() {
      if (this.isExpanded4) {
        return this.listItems;
      } else {
        return this.listItems.slice(0, 3);
      }
    },
    
  },
  methods: {
    toggleExpansion4() {
      this.isExpanded4 = !this.isExpanded4;
    },
   
  },
  };
  </script>
  
  <style>
  .expanded .v-card__text > div {
  display: block; /* Adjust this as needed */
  }
  </style>y
  